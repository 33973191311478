import '../../lib/webpack-path.js';
import type { Options } from '@splidejs/splide';
import { appendCSSText } from '../../lib/css-dom.js';
import { retry } from '../../lib/promise-retry.js';
import { splideSlidebarExtension } from '../../lib/splide-slidebar-extension.js';
import styles from './index.scss';

/**
 * Inits collection nav carousel
 */
function onDOMContentLoaded(_event: Event) {
  init().catch(console.warn);
}

async function init() {
  // https://splidejs.com/guides/options/#options
  const options: Options = {
    type: 'slide',
    role: 'group',
    // autoWidth: true,
    arrows: true,
    pagination: false,
    perPage: 1,
    perMove: 1,
    gap: 8,
    keyboard: true,
    mediaQuery: 'min',
    slidebar: true,
    breakpoints: {
      430: {
        perPage: 2,
        perMove: 2
      },
      768: {
        perPage: 3,
        perMove: 3
      },
      1024: {
        gap: 18,
        perPage: 4,
        perMove: 4
      },
      1440: {
        perPage: 5,
        perMove: 5
      },
      1600: {
        perPage: 6,
        perMove: 6
      }
    }
  };

  const carousels = document.querySelectorAll<HTMLElement>('.collection-nav-carousel-carousel');
  if (carousels.length === 0) {
    return;
  }

  const { Splide } = await retry(import('@splidejs/splide'));

  Array.from(carousels).forEach((carousel, index) => {
    // Calculate the navigation card that is "active" and focus it by adding a setting to the splide
    // config
    const anchors = carousel.querySelectorAll('.collection-nav-carousel-slide');

    let focusedSlide = null;
    for (let j = 0; j < anchors.length; j++) {
      if (anchors[j].classList.contains('active-url')) {
        focusedSlide = j;
      }
    }

    const splide = new Splide(carousel, options);

    splide.on('mounted', () => {
      // We need to check the overflow in both "mounted" event and "overflow". Having that only in
      // "overflow" leads to setting a wrong value the time to time.
      splide.options = {
        padding: {
          right: splide.Components.Layout.isOverflow() ? 40 : 0
        }
      };

      if (focusedSlide) {
        splide.go(focusedSlide);
      }

      // TODO: figure out what is going on here
      // splide.Components.Slides.forEach(component => {
      //   a11y.makeInert(component.slide);
      // });
    });

    splide.on('visible', component => {
      component.slide.removeAttribute('inert');
    });

    // TODO: temporarily disabled
    // splide.on('hidden', component => {
    //   a11y.makeInert(component.slide);
    // });

    splide.on('overflow', isOverflow => {
      splide.options = {
        padding: {
          right: isOverflow ? 40 : 0
        }
      };
    });

    if (index < 1) {
      splide.mount({
        splideSlidebarExtension
      });
    } else {
      // As there are about 8 carousels to render we have to delay initialization for those not in
      // the viewport as it affect performance because of long running tasks
      const mountSplide = splide.mount.bind(splide, { splideSlidebarExtension });
      setTimeout(mountSplide, 500 + (50 * index));
    }
  });
}

appendCSSText(styles);

if (document.readyState === 'complete' || document.readyState === 'interactive') {
  setTimeout(onDOMContentLoaded);
} else {
  addEventListener('DOMContentLoaded', onDOMContentLoaded);
}
